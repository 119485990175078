<style  scoped>
.loadbox {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
}
.loadbox-1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
  background-color: rgba(240, 248, 255, 0.745);
  border-radius: 10px;
}
.load-1 {
  width: 98%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.load-2 {
  width: 100%;
  height: auto;
}
.title {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
p {
  word-wrap: break-word;
  word-break: break-all;
}
.line {
  background-color: #093b9723;
  width: 2px;
  height: 60px;
}
.apply-1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
}
.load-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.load-4 {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  width: 80%;
  text-align: left;
}

.load-4 p {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  width: 80%;
  text-align: left;
}

@media screen and (max-width: 800px) {
  .loadbox-1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: left;
  }
  .load-4 {
    width: 100%;
  }

  .load-4 p {
    width: 100%;
  }
}
/* ::v-deep .el-step__icon.is-text {
  border-radius: 50%;
  border: none;
}
::v-deep .el-step__title.is-process {
  font-weight: 500;
  color: #303133;
}

::v-deep .el-step__head.is-finish {
  color: #303133;
  border-color: #303133;
} */
.flag0 {
  color: #c0c4cc;
}
.flag1 {
  color: #67c23a;
}
.flag2 {
  color: red;
}
.flag0 .el-step__main .el-step__title {
  color: #243649 !important;
}
::v-deep .el-step__title.is-process {
  font-weight: 700;
  color: #67c23a !important;
}
::v-deep .el-step__head.is-process {
  color: #67c23a;
  border-color: #67c23a;
}
.topShow {
    width: 100%;
    height: 27px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #a95159;
    font-size: 15px;
    color: #fff;
    border: none;
    cursor: pointer;
}

</style>

<template>
  <div class="loadbox">
    <div class="loadbox-1" v-if="loadvisible">
      <div class="load-1">
        <div class="load-2">
          <div style="display: inline-block;width: 45%;padding-right: 3%;padding-left: 2%;">
              <p class="topShow" style="font-size:14px;font-weight: 700;text-align: center;">Loan Number</p>
              <p style="margin-left: 5%;">{{loadinfo.code}}</p>
          </div>
          <div style="display: inline-block;width: 45%;padding-right: 3%;float: right;">
              <p class="topShow" style="font-size:14px;font-weight: 700;text-align: center;">Date Applied</p>
              <p style="text-align: center;">{{loadinfo.time}}</p>
          </div>
          <div style="">
            <!-- <el-steps :active="active" align-center finish-status="success">
              <el-step title="Submit complete" :status="step0" description></el-step>
              <el-step title="Under review" :status="step1" description></el-step>
              <el-step title="Approve" :status="step2" description></el-step>
              <el-step title="Successful withdrawal" status="wait" description></el-step>
            </el-steps> -->
            <div style="border-bottom: 1px solid black;">
               <p class="topShow" style="font-size:14px;font-weight: 800;text-align: center;width:45%;margin-left: 2%;">Current status</p>
            </div>
            <div style="">
            <p style="font-size:14px;font-weight: 800;width:45%;margin-left: 5%;">{{loadinfo.info}}</p>
            </div>
          </div>
          <div>
            <div class="apply-1" style="border-bottom: 1px solid black;border-top: 1px solid black;">
              <div style="width:45%;border-right: 1px solid black;" >
                  <p class="topShow" style="width:80%;ont-size:14px;font-weight: 800;text-align: center;;margin-left: 2%;" >{{loadinfo.borrowamount}}₱</p>
                <p style="margin-left: 5%;font-weight:500;font-size:18px">Loan amount</p>
              </div>
              <div style="width:45%">
                <p class="topShow" style="width:80%;ont-size:14px;font-weight: 800;text-align: center;;margin-left: 2%;">{{loadinfo.term}} month</p>
                <p style="font-weight: 500;font-size:18px">Monthly Installment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width:100%">
        <div style="display: inline-block;padding-left: 2%;width: 45%;">
          <p class="topShow" style="font-size:14px;font-weight: 700;text-align: center;padding-left: 10%;">
            Loan Application Status :
          </p>
        </div>
        <div style="display: inline-block;float: right;width: 35%;padding-right: 5%;margin-top: 3%;">
            <button class="topShow" style="font-size:14px;font-weight: 700;text-align: center;" @click="goToConstract">View Contract</button>
        </div>
        <p style="font-size:16px;font-weight: 700;padding: 0 20px">{{loadinfo.state}}</p>
      </div>
      <!-- <div class="load-1">
        <p style="font-size:20px;font-weight: 700;">. Loan Details</p>
        <div class="load-2">
          <div class="load-4">
            <p style="font-size:20px;">Order number</p>
            <p style="font-size:25px;font-weight: 700;">{{loadinfo.code}}</p>
          </div>
          <div class="load-4">
            <p style="font-size:20px;">Loan amount</p>
            <p style="font-size:25px;font-weight: 700;">{{loadinfo.borrowamount}}₱</p>
          </div>
          <div class="load-4">
            <p style="font-size:20px">Borrowing time</p>
            <p style="font-size:25px;font-weight: 700;">{{loadinfo.term}} month</p>
          </div>
          <div class="load-4">
            <p style="font-size:20px;">Monthly payment</p>
            <p style="font-size:25px;font-weight: 700;">{{syhk}}₱</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      active: 3,
      flag1: "flag0",
      step0: "wait",
      step1: "wait",
      step2: "wait",
      accountBlance: 0,
      visible: false,
      loadvisible: false,
      loanAmount: "",
      loadinfo: {
        borrowamount: 0,
        code: "0",
        info: null,
        state: null,
        term: 0,
        time: "0",
        flag: 0
      },
      syhk: ""
    };
  },
  mounted() {
    this.getLoad();
  },
  methods: {
    goToConstract() {
      this.$router.push("/constract2");
    },
    getLoad() {
      axios
        .get(
          `borrowingSys/borrowing/borro/selectstate?uid=${+sessionStorage.getItem(
            "token"
          )}`,
          {
            withCredentials: true
          }
        )
        .then(res => {
          // console.log(1,res.data);
          if (res.data != null) {
            if (res.data.data.syhk != null) {
              this.loadinfo = res.data.data.pbsetate;
             // this.loadinfo.flag =1;
              if (this.loadinfo.flag == -1) {
                this.flag1 = "flag2";
                this.step0 = "error";
                this.active = 1;
                // this.loadinfo.info = "Not passed";
                // this.loadinfo.state="Incomplete information"
              }
              if (this.loadinfo.flag == 0) {
                this.flag1 = "flag2";
                this.step0 = "process";
                this.flag1 = "flag0";
                this.step1 = "process";
                this.active = 2;
                // this.loadinfo.info = "Under review";
                //  this.loadinfo.state="Your order is currently under review"
              }
              if (this.loadinfo.flag == 1) {
                this.step0 = "process ";
                this.step1 = "process ";
                this.flag1 = "flag1";
                this.step2 = "process";
                this.active = 3;
                // this.loadinfo.info = "Approve";
                // this.loadinfo.state="Your review has been approved"
              }

              this.syhk = res.data.data.syhk;
              this.loadvisible = true;
            }
          }
          //console.log(res.data);
        })
        .catch(error => {
          // ////////console.log(error);
        });
    }
  }
};
</script>

