<template>
  <div id="app">
    <!-- <loadDetails /> -->
    <router-view />

  </div>
</template>


<script>
import loadDetails from "@/components/loadDetails.vue";
import axios from "axios";
export default {
  components: {
    loadDetails
  },
  }
</script>
<style>
@font-face {
  font-family: 'Gotham'; /* 这里定义字体名称，可以自定义 */
  src: url('./../public/gotham/gotham_jxdown.com/GothamProNarrowMedium.ttf') /* 优先使用woff2格式 */
}
#app {
  font-family: "Gotham";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
body{
   margin: 0px;
  padding: 0px;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
