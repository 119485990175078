<template>
  <div class="b">
    <div class="menu_box">
      <div class="buts" style="border: 1px solid black">
        <router-link to="/">
          <button class="but but0">
            <svg
              t="1709446370710"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="6955"
              width="30"
              height="30"
            >
              <path
                d="M511.990798 165.576356 172.136671 479.062438l36.621604 58.594975 61.52544-26.368046 0 287.118036 483.414168-2.930464L753.697881 511.289367l60.06123 26.368046 38.087858-55.666555L511.990798 165.576356zM799.108834 496.641135l-83.498811-39.552068 2.930464 300.302059L468.044055 757.391125l-1.46421-284.187572-99.613298 1.46421 0 287.118036-65.920114 0-2.930464-301.766269-74.709462 36.621604-8.789349-14.648233 297.371594-266.608875 294.443175 266.608875L799.108834 496.641135z"
                fill="black"
                p-id="6956"
              />
              <path
                d="M516.385472 468.806835l161.138738 0 0 161.138738-161.138738 0 0-161.138738Z"
                fill="black"
                p-id="6957"
              />
              <path
                d="M873.818296 150.926079C780.065927 58.63792 652.620374 1.507154 511.990798 1.507154c-142.093786 0-269.539339 57.130765-361.827498 149.418925C57.87514 244.678448 0.744375 372.124001 0.744375 512.753577c0 142.093786 57.130765 269.539339 149.418925 361.827498 92.288159 92.288159 219.733713 149.418925 361.827498 149.418925 140.629576 0 268.075129-57.130765 361.827498-149.418925 92.288159-92.288159 149.418925-219.733713 149.418925-361.827498C1023.23722 372.124001 966.106455 244.678448 873.818296 150.926079zM831.337808 833.564797c-82.034601 80.568346-194.829877 131.840228-319.344966 131.840228s-238.776619-51.271881-320.81122-131.840228c-80.568346-82.034601-131.840228-196.296132-131.840228-320.81122s51.271881-237.31241 131.840228-319.344966c82.034601-82.034601 196.296132-133.304437 320.81122-133.304437s237.31241 51.271881 319.344966 133.304437 133.304437 194.829877 133.304437 319.344966S913.370364 751.532241 831.337808 833.564797z"
                fill="black"
                p-id="6958"
              />
            </svg>Home
          </button>
        </router-link>
        <div class="xian"></div>
        <router-link to="/wallet">
          <button class="but but1">
            <svg
              t="1709447721455"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="12599"
              width="30"
              height="30"
            >
              <path
                d="M832.36132813 344.80247107H210.09570287l-3.95507812-14.50195286c-9.22851588-31.640625 6.59179662-65.25878906 36.25488332-78.44238307L639.88085963 75.19797915C655.70117213 67.94700233 674.15820313 67.28782289 690.63769505 73.87961952s29.66308594 18.457031 36.91406276 34.93652368L832.36132813 344.80247107z m-588.6474612-39.55078125h527.34375026L691.29687525 124.63645571c-2.6367185-6.59179662-7.91015625-11.20605494-14.50195363-13.84277345-6.59179662-2.6367185-13.84277344-2.6367185-20.43457006 0.65917945L258.875 288.1130177c-7.91015625 3.29589869-12.52441381 9.88769531-15.16113307 17.13867212z"
                fill="black"
                p-id="12600"
              />
              <path
                d="M832.36132813 344.80247107H136.26757812v-32.95898464c0-61.96289037 50.097656-112.06054713 112.06054713-112.06054637h111.40136694L639.88085963 75.19797915C655.70117213 67.94700233 674.15820313 67.28782289 690.63769505 73.87961952s29.66308594 18.457031 36.91406276 34.93652368L832.36132813 344.80247107zM175.81835937 305.25168982h595.23925782L691.29687525 124.63645571c-2.6367185-6.59179662-7.91015625-11.20605494-14.50195363-13.84277345-6.59179662-2.6367185-13.84277344-2.6367185-20.43457006 0.65917945L368.29882787 239.33372131H248.32812525c-37.57324219 0-68.55468775 29.0039065-72.50976588 65.91796851z"
                fill="black"
                p-id="12601"
              />
              <path
                d="M834.99804662 858.96262732H189.00195338c-29.0039065 0-52.73437525-23.73046875-52.73437525-52.73437526V305.25168982h698.73046849c29.0039065 0 52.73437525 23.73046875 52.73437526 52.73437526v448.24218698c0 29.0039065-23.73046875 52.73437525-52.73437526 52.73437526zM175.81835937 344.80247107v461.42578099c0 7.25097682 5.93261719 13.18359399 13.18359401 13.18359402h645.99609324c7.25097682 0 13.18359399-5.93261719 13.18359401-13.18359402V357.98606508c0-7.25097682-5.93261719-13.18359399-13.18359401-13.18359401H175.81835937z"
                fill="black"
                p-id="12602"
              />
              <path
                d="M719.64160156 680.98411171c-30.98144557 0-56.03027318-25.04882838-56.03027318-56.03027319v-79.1015625c0-30.98144557 25.04882838-56.03027318 56.03027318-56.03027395s56.03027318 25.04882838 56.03027319 56.03027395v79.1015625c0 30.98144557-25.04882838 56.03027318-56.03027319 56.03027317z m0-151.61132839c-9.22851588 0-16.47949193 7.25097682-16.47949193 16.4794927v79.1015625c0 9.22851588 7.25097682 16.47949193 16.47949193 16.47949193s16.47949193-7.25097682 16.47949194-16.47949193v-79.1015625c0-9.22851588-7.25097682-16.47949193-16.47949194-16.4794927zM347.47534154 307.70383825l369.36474609-157.28027317 15.49072318 36.38671875-369.35815456 157.28027317z"
                fill="black"
                p-id="12603"
              />
            </svg>Wallet
          </button>
        </router-link>
        <div class="xian"></div>
        <router-link to="/account">
          <button class="but but2">
            <svg
              t="1709447619036"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="9925"
              width="30"
              height="30"
            >
              <path
                d="M917.901055 147.245575 128.015094 147.245575c-32.090882 0-58.18826 26.075889-58.18826 58.125839l0 567.459103c0 32.04688 26.097378 58.125839 58.18826 58.125839l789.885961 0c32.132838 0 58.168818-26.076912 58.168818-58.125839L976.069873 205.371414C976.068849 173.321464 950.033893 147.245575 917.901055 147.245575M932.999938 772.830517c0 8.243755-6.771217 15.056927-15.099906 15.056927L128.015094 787.887444c-8.3072 0-15.120372-6.813173-15.120372-15.056927L112.894721 205.371414c0-8.243755 6.813173-15.057951 15.120372-15.057951l789.885961 0c8.328689 0 15.099906 6.814196 15.099906 15.057951L933.000961 772.830517z"
                fill="black"
                p-id="9926"
              />
              <path
                d="M432.709631 511.054465c31.585369-24.14184 52.405557-61.827137 52.405557-104.474446 0-72.677253-59.092863-131.813095-131.729184-131.813095-72.636321 0-131.729184 59.134819-131.729184 131.813095 0 42.647309 20.797675 80.332606 52.405557 104.474446-70.406536 30.703279-119.846552 100.857059-119.846552 182.535313 0 11.861142 9.610892 21.534456 21.534456 21.534456 11.881608 0 21.534456-9.673313 21.534456-21.534456 0-84.706217 67.818595-153.515373 151.937434-155.701155 1.429559 0 2.734274 0.419556 4.163833 0.419556 1.428535 0 2.734274-0.419556 4.119831-0.419556 84.201727 2.187829 152.000879 70.994937 152.000879 155.701155 0 11.861142 9.631358 21.534456 21.534456 21.534456s21.534456-9.673313 21.534456-21.534456C552.577672 611.911524 503.116167 541.757744 432.709631 511.054465M264.726755 406.581042c0-48.957015 39.745213-88.745207 88.661296-88.745207 48.872081 0 88.660272 39.788192 88.660272 88.745207 0 47.441499-37.600363 85.967954-84.538395 88.23867-1.385557-0.083911-2.691295-0.420579-4.119831-0.420579-1.429559 0-2.735297 0.336668-4.163833 0.420579C302.285163 492.547972 264.726755 454.022541 264.726755 406.581042"
                fill="black"
                p-id="9927"
              />
              <path
                d="M846.863139 314.13556 558.886355 314.13556c-11.945053 0-21.534456 9.673313-21.534456 21.533432 0 11.861142 9.589402 21.534456 21.534456 21.534456l287.976784 0c11.904121 0 21.534456-9.673313 21.534456-21.534456C868.397594 323.808874 858.76726 314.13556 846.863139 314.13556"
                fill="black"
                p-id="9928"
              />
              <path
                d="M846.863139 446.957635 558.886355 446.957635c-11.945053 0-21.534456 9.589402-21.534456 21.533432 0 11.861142 9.589402 21.534456 21.534456 21.534456l287.976784 0c11.904121 0 21.534456-9.673313 21.534456-21.534456C868.397594 456.547037 858.76726 446.957635 846.863139 446.957635"
                fill="black"
                p-id="9929"
              />
              <path
                d="M846.863139 579.778686 601.954243 579.778686c-11.94403 0-21.533432 9.589402-21.533432 21.533432 0 11.861142 9.589402 21.534456 21.533432 21.534456l244.908896 0c11.904121 0 21.534456-9.673313 21.534456-21.534456C868.397594 589.369112 858.76726 579.778686 846.863139 579.778686"
                fill="black"
                p-id="9930"
              />
            </svg>Account
          </button>
        </router-link>
        <div class="xian"></div>
        <router-link to="/about">
          <button class="but but3">
            <svg
              t="1709447679531"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="11240"
              width="30"
              height="30"
            >
              <path
                d="M768 384C732.672 384 704 412.672 704 448 704 483.328 732.672 512 768 512 803.328 512 832 483.328 832 448 832 412.672 803.328 384 768 384L768 384ZM512 832C474.624 832 438.528 827.648 403.84 820.256L253.184 910.816 255.2 762.368C139.712 692.928 64 578.112 64 448 64 235.936 264.576 64 512 64 759.424 64 960 235.936 960 448 960 660.096 759.424 832 512 832L512 832ZM512 0C229.248 0 0 200.608 0 448 0 589.408 75.04 715.328 192 797.408L192 1024 416.288 887.904C447.328 893.024 479.264 896 512 896 794.752 896 1024 695.424 1024 448 1024 200.608 794.752 0 512 0L512 0ZM256 384C220.672 384 192 412.672 192 448 192 483.328 220.672 512 256 512 291.328 512 320 483.328 320 448 320 412.672 291.328 384 256 384L256 384ZM512 384C476.672 384 448 412.672 448 448 448 483.328 476.672 512 512 512 547.328 512 576 483.328 576 448 576 412.672 547.328 384 512 384L512 384Z"
                fill="black"
                p-id="11241"
              />
            </svg>About
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
a{
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}

.menu-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.b {
  position: fixed;
  width: 100%;
  z-index: 9999;
  bottom: 0;
  background-color: #091730;
  left: 0;
}
.menu_box {
  display: flex;
  justify-content: center;
  background-color: white;
  width: 100%;
}
.buts {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px 0 5px 0;
}
.buts .but {
  height: 50px;
  border: none;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.001);
  cursor: pointer;
  color: black;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.but0::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but0:hover::before {
  width: 100%;
}

.but1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but1:hover::before {
  width: 100%;
}
.but2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but2:hover::before {
  width: 100%;
}
.but3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but3:hover::before {
  width: 100%;
}
.but4::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but4:hover::before {
  width: 100%;
}
.but5::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* 下划线高度 */
  background-color: #f3eaea;
  transition: width 0.3s ease-in-out; /* 过渡效果 */
}
.but5:hover::before {
  width: 200%;
}
.searchs {
  width: 350px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.search {
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 20px;
  width: 250px;
  height: 30px;
  padding: 5px;
}

.search input {
  width: 160px;
  padding: 5px;
  outline: none;
  border: none;
}
.search button {
  width: 60px;
  padding: 3px;
  outline: none;
  margin-left: 3px;
  border: none;
  background-color: white;
  cursor: pointer;
}
.user {
  border: none;
  background-color: white;
  cursor: pointer;
}
.xian{
  width: 1px;
  border-right: 1px solid black
}
</style>

